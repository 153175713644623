import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isEmpty, isSameOrFutureMonth} from '@matchsource/utils';

export const SAME_OR_FUTURE_MONTH_VALIDATOR_KEY = 'same-future-month';

export const sameOrFutureMonthValidator = (control: AbstractControl): ValidationErrors | null => {
  const isValid = isEmpty(control.value) || isSameOrFutureMonth(control.value);

  return !isValid ? {[SAME_OR_FUTURE_MONTH_VALIDATOR_KEY]: {value: control.value}} : null;
};
