<ms-dynamic-dialog customClass="error-dialog">
  <ng-template let-data>
    <div class="simple-component-wrapper">
      <div class="modal-header">
        <h4 class="modal-title" transloco="ERRORS.TECHNICAL_DIFFICULTIES_MODAL_TITLE"></h4>
      </div>
      <div class="modal-body no-bottom-margin">
        <p *ngIf="flags.showLoadingDetailsTemplate" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.PLEASE_HOLD_WHILE_THE_DETAILS_ARE_LOADING' | transloco | format"></p>

        <p *ngIf="!flags.showSubmitErrorTemplate && !flags.showLoadingDetailsTemplate" [innerHTML]="data | errorMessageTranslationKey | transloco | format" class="padding-after"></p>

        <ng-container *ngIf="flags.showCreateIncidentTemplate">
          <div class="padding-after">
            <p [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.REPORT_THIS_PROBLEM_TO_THE_SERVICE_DESK' | transloco | format"></p>
            <p [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.YOU_WILL_RECEIVE_A_CONFIRMATION_EMAIL' | transloco | format"></p>
          </div>
          <p class="half-padding-after"
            [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.REPORT_A_PROBLEM' | transloco | format"></p>
          <p class="padding-after"
            [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.SUBMITTING_PROBLEMS_INFORMS_US' | transloco | format"></p>
          <p class="half-padding-after"
            [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.IF_YOU_CHOOSE_TO_ENTER_DETAILS' | transloco | format"></p>
          <form [formGroup]="form">
            <ms-form-field [label]="'TECHNICAL_DIFFICULTIES_POPUP.OPTIONAL_COMMENTS' | transloco" fieldClass="ms-full-width">
              <textarea type="text" [formControl]="form.controls.comments"
                [placeholder]="'TECHNICAL_DIFFICULTIES_POPUP.ENTER_DETAILS_ABOUT_THE_PROBLEM' | transloco"
                class="optional-comments ms-full-width"></textarea>
            </ms-form-field>
          </form>
          <div class="modal-footer incident-buttons double-padding-after">
            <ms-button-panel size="large">
              <button type="button" msButton="secondary" [msModalClose]="true">{{'TECHNICAL_DIFFICULTIES_POPUP.IGNORE' | transloco}}</button>
            </ms-button-panel>
            <ms-button-panel size="large">
              <button type="button" msButton="action" (click)="onSubmitIncident()">{{'TECHNICAL_DIFFICULTIES_POPUP.SUBMIT_INCIDENT' | transloco}}</button>
            </ms-button-panel>
          </div>
          <p *ngIf="flags.isTcUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.AFTER_SUBMITTING_THIS_INCIDENT_PLEASE_CONTACT_YOUR_CASE_MANAGER' | transloco | format"></p>
          <p *ngIf="flags.isCpUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.AFTER_SUBMITTING_THIS_INCIDENT_PLEASE_CONTACT_YOUR_CLINICAL_OPERATIONS_PARTNER' | transloco | format"></p>
          <p *ngIf="flags.isInternalUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.AFTER_SUBMITTING_THIS_INCIDENT_PLEASE_CONTACT_THE_SERVICEDESK' | transloco | format"></p>
        </ng-container>

        <div *ngIf="flags.showCantCreateIncidentsTooOftenTemplate">
          <div class="double-padding-after">
            <p *ngIf="flags.isTcUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.MULTIPLE_TECHNICAL_DIFFICULTIES_CONTACT_YOUR_CASE_MANAGER' | transloco | format"></p>
            <p *ngIf="flags.isCpUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.MULTIPLE_TECHNICAL_DIFFICULTIES_CONTACT_YOUR_CLINICAL_OPERATIONS_PARTNER' | transloco | format"></p>
            <p *ngIf="flags.isInternalUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.MULTIPLE_TECHNICAL_DIFFICULTIES_CONTACT_THE_SERVICEDESK' | transloco | format"></p>
          </div>
          <ng-container [ngTemplateOutlet]="okButtonOnly"></ng-container>
        </div>

        <div *ngIf="flags.showSubmitErrorTemplate">
          <p [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.WE_ARE_EXPERIENCING_ISSUES_SUBMITTING_INCIDENTS' | transloco | format"
            class="padding-after"></p>
          <div class="padding-after">
            <p *ngIf="flags.isTcUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.TO_SUBMIT_THE_INCIDENT_CALL_THE_SERVICE_DESK_THEN_CONTACT_YOUR_CASE_MANAGER' | transloco | format" ></p>
            <p *ngIf="flags.isCpUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.TO_SUBMIT_THE_INCIDENT_CALL_THE_SERVICE_DESK_THEN_CONTACT_YOUR_CLINICAL_OPERATIONS_PARTNER' | transloco | format" ></p>
            <p *ngIf="flags.isInternalUser" [innerHTML]="'TECHNICAL_DIFFICULTIES_POPUP.TO_SUBMIT_THE_INCIDENT_CALL_THE_SERVICE_DESK_OR_SERVICE_CENTER_PORTAL' | transloco | format" ></p>
          </div>
          <ng-container [ngTemplateOutlet]="okButtonOnly"></ng-container>
        </div>

        <div data-ms-hidden-error-info style="display: none;">{{hiddenErrorInfo}}</div>
      </div>
    </div>
    <ng-template #okButtonOnly>
      <div class="modal-footer">
        <ms-button-panel size="large">
          <button type="button" msButton="action" [msModalClose]="true">{{'COMMON.OK' | transloco}}</button>
        </ms-button-panel>
      </div>
    </ng-template>
  </ng-template>
</ms-dynamic-dialog>
