import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {IncidentCreationModel} from './incident-creation.model';
import {TechnicalDifficultiesIncidentCreationRequest} from '@matchsource/api-generated/common';

@Injectable({
  providedIn: 'root',
})
export class IncidentCreationSerializerService
  implements Serializer<IncidentCreationModel, TechnicalDifficultiesIncidentCreationRequest>
{
  toDTO(model: IncidentCreationModel): TechnicalDifficultiesIncidentCreationRequest {
    return {
      currentUserLocation: model.currentUserLocation,
      errorHttpCode: model.errorHttpCode,
      errorMessage: model.errorMessage,
      errorTraceId: model.errorTraceId,
      errorUrl: model.errorUrl,
      type: model.type,
      userDescription: model.userDescription,
    };
  }
}
