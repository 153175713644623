import {Pipe, PipeTransform} from '@angular/core';
import {ClientErrorCode, ERROR_MESSAGE_PATTERNS, BasicError} from '@matchsource/error-handling/core';

@Pipe({
  name: 'errorMessageTranslationKey',
  standalone: true,
})
export class ErrorMessageTranslationKeyPipe implements PipeTransform {
  transform(error: BasicError): string {
    if (!error) {
      return ERROR_MESSAGE_PATTERNS[ClientErrorCode.Default];
    }
    return ERROR_MESSAGE_PATTERNS[error.errorCode] || ERROR_MESSAGE_PATTERNS[ClientErrorCode.Default];
  }
}
