import {Injectable, Injector, OnDestroy} from '@angular/core';
import {DialogInstance, DialogService} from '@matchsource/nmdp-ui';
import {Subject} from 'rxjs';
import {DynamicErrorDialogComponent} from './components/dynamic-error-dialog/dynamic-error-dialog.component';
import {ERROR_DIALOG_ID, ErrorDialogData, BasicError} from '@matchsource/error-handling/core';
import {ERROR_NOTIFICATION} from '@matchsource/core';
import {EventService} from '@matchsource/event';

@Injectable({providedIn: 'root'})
export class ErrorHandlingService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private dialogService: DialogService;

  constructor(
    private readonly injector: Injector,
    private readonly eventService: EventService
  ) {}

  init(): void {
    this.eventService.on(ERROR_NOTIFICATION, data => this.showErrorModal(data), this.destroy$);
  }

  showErrorModal(data: BasicError): DialogInstance | null {
    if (!this.dialogService) {
      this.dialogService = this.injector.get(DialogService);
    }

    if (this.dialogService.hasDialog(ERROR_DIALOG_ID)) {
      return null;
    }

    return this.dialogService.invoke(ERROR_DIALOG_ID, DynamicErrorDialogComponent).open<ErrorDialogData>(data);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
